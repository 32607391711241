import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import pressData from "../data/press-data"

class Press extends React.Component {
  render() {
    let rowContents = []

    const contents = pressData.map((article, i) => {
      let acc = []
      rowContents.push(
        <>
          <div className="col s12 m5" key={article.id}>
            <h2 className="center-align press-title">{article.title}</h2>
            <p className="main-content">{article.content}</p>
            <a
              href={article.link}
              target="_blank"
              rel="noreferrer"
              className="press-link"
            >
              <p className="main-content">{article.website}</p>
            </a>
          </div>
          <div className="col s12 m2" />
        </>
      )
      if ((i + 1) % 2 === 0) {
        acc.push(<div className="row">{rowContents}</div>)
        rowContents = []
      }
      return acc
    }, [])
  contents.push(<div>{rowContents}</div>)


    return (
      <Layout>
        <SEO title="Press" />
        <div className="container">
          <div className="row">
            <div className="col s12">
              <h1>Press</h1>
            </div>
          </div>
          {contents}
        </div>
      </Layout>
    )
  }
}

export default Press
