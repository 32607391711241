const pressData = [
  {
    title: "Wild Story Of Country Star Mickey Gilley & Honky-Tonk That Inspired ‘Urban Cowboy’ Set Up As Limited Series",
    content: "Paramount+ revealed earlier this week that it had a series adaptation of John Travolta movie Urban Cowboy in the works. Now, the wild, true story that inspired the 1980 film is also being developed for television",
    link: "https://deadline.com/2022/02/micky-gilley-honky-tonk-urban-cowboy-limited-series-1234925566/",
    website: "deadline.com"
  },
  {
    title: "Producers Maryann Garger & Michael Becker Partner To Launch Imprint Family Entertainment",
    content: "Producers Maryann Garger (If Anything Happens, I Love You) and Michael Becker (Primal, Worth) have partnered to launch Imprint Family Entertainment.",
    link: "https://deadline.com/2021/07/maryann-garger-michael-becker-launch-imprint-family-entertainment-1234797003/",
    website: "deadline.com"
  },
  {
    title: "Imprint Family Entertainment Launches With Animated Film GROOVE TAILS Starring Jamie Foxx",
    content: "Maryann Garger, who produced this year's Oscar®-winning animated short film, IF ANYTHING HAPPENS, I LOVE YOU, and producer/CEO of Imprint Entertainment Michael Becker, announce that they have partnered to launch Imprint Family Entertainment (IFE).",
    link: "https://www.broadwayworld.com/bwwtv/article/Imprint-Family-Entertainment-Launches-With-Animated-Film-GROOVE-TAILS-Starring-Jamie-Foxx-20210722",
    website: "broadwayworld.com"
  },
  {
    title: "Imprint Entertainment launches kids division",
    content: "Maryann Garger and Michael Becker will head up Imprint Family Entertainment and create films, TV series and podcasts, starting with Groove Tails.",
    link: "https://kidscreen.com/2021/07/22/imprint-entertainment-launches-kids-division/",
    website: "kidscreen.com"
  },
  {
    title:
      "Sundance 2020: Premieres Shine With Taylor Swift Docu, Viggo Mortensen’s Directing Debut, New Dee Rees, The Go-Gos & Olivia Colman",
    content:
      "No one is confirming it yet, but the 2020 Sundance Film Festival could include a superstar jam between Taylor Swift and the Go-Gos.",
    link:
      "https://deadline.com/2019/12/sundance-film-festival-premieres-lineup-taylor-swift-viggo-mortensendee-rees-go-gos-full-list-1202800556/?fbclid=IwAR1gUI0H9OfqOtC8TwsYBpRJe4VMmyhRtc95R1kbhd6jKDVsm6DoLxlGRVU",
    website: "deadline.com",
  },
  {
    title:
      "Sundance Wish List: 60 Films We Hope Will Head to Park City in 2020",
    content:
      "While the Sundance lineup is still a few weeks away, our educated guesswork paints a very exciting picture of the 2020 program.",
    link:
      "https://www.indiewire.com/2019/11/2020-sundance-films-wish-list-1202190036/",
    website: "indiewire.com",
  },
  {
    title: "Nicolas Cage to Star in Action Film 'Primal' (Exclusive)",
    content:
      "The film about a big game hunter on a deadly trip hauling exotic animals is set to begin shooting Oct. 23 in Puerto Rico.",
    link:
      "https://www.hollywoodreporter.com/news/nicolas-cage-star-action-film-primal-tiff-2017-1037983",
    website: "hollywoodreporter.com",
  },
  {
    title:
      "Michael Chiklis, Forest Whitaker, Ray Liotta, and Common Team Up for Crime Thriller PAWN",
    content:
      "The Shield co-stars Michael Chiklis and Forest Whitaker are set to reunite for the crime thriller Pawn.",
    link:
      "http://collider.com/michael-chiklis-forest-whitaker-pawn-ray-liotta-common/129017/",
    website: "collider.com",
  },
  {
    title:
      "'Shield' Cohorts Michael Chiklis And Forest Whitaker Team For Indie Feature 'Pawn'",
    content:
      "Michael Chiklis is teaming with former The Shield co-star Forest Whitaker for Pawn, an independent crime film centered around a hostage situation that has gone terribly wrong.",
    link:
      "https://deadline.com/2011/11/shield-cohorts-michael-chiklis-and-forest-whitaker-team-for-indie-feature-pawn-198492/",
    website: "deadline.com",
  },
  {
    title:
      "‘What Is Life Worth’ Adds Amy Ryan, Talia Balsam & Tate Donovan As Filming Begins",
    content:
      "The Sara Colangelo-directed What Is Life Worth has commenced principal photography in New York with new additions to the ensemble cast.",
    link:
      "https://deadline.com/2019/04/what-is-life-worth-amy-ryan-tate-donovan-talia-balsam-cast-shoot-start-1202602099/",
    website: "deadline.com",
  },
  {
    title: "Syfy Sets ‘Zombie Tidal Wave’ With ‘Sharknado’ Star Ian Ziering",
    content:
      "Ian Ziering is returning to Syfy, but don’t worry, it’s not another “Sharknado” film.",
    link:
      "https://www.thewrap.com/syfy-sets-zombie-tidal-wave-with-sharknado-star-ian-ziering/",
    website: "thewrap.com",
  },
  {
    title:
      "Ian Ziering’s ‘Zombie Tidal Wave’ Movie To Premiere As Part Of Syfy’s “Off The Deep End Weekend”",
    content:
      "Zombie Tidal Wave, a new Syfy original movie starring and produced by Ian Ziering (Sharknado), is set to premiere as part of Syfy’s shark-themed Off The Deep End weekend.",
    link:
      "https://deadline.com/2019/06/ian-ziering-zombie-tidal-wave-movie-premiere-syfy-off-the-deep-end-weekend-1202638523/",
    website: "deadline.com",
  },
  {
    title: "Check Out ShockYa’s Exclusive Clip From Pawn",
    content:
      "Anchor Bay Entertainment has announced the thriller “Pawn,” starring Forest Whitaker and Michael Chiklis, will be available on DVD and Blu-ray April 23.",
    link:
      "https://www.shockya.com/news/2013/04/19/check-out-shockyas-exclusive-clip-from-pawn/",
    website: "shockya.com",
  },
  {
    title:
      "Michael Rapaport, ‘Hangover’ Actor Bryan Callen Star in ‘My Man Is A Loser’",
    content:
      "The “Hitch” actor, who also directed the A Tribe Called Quest documentary “Beats, Rhymes & Life,” will star in this Imprint Entertainment project.",
    link:
      "https://www.thewrap.com/michael-rapaport-hangover-actor-bryan-callen-star-my-man-loser-40231/",
    website: "thewrap.com",
  },
  {
    title:
      "Pam Grier’s Autobiography ‘Foxy, My Life in Three Acts’ Getting Big Screen Treatment",
    content:
      "According to Deadline, Imprint Entertainment have optioned the film rights to legendary blaxploitation star Pam Grier‘s autobiography, Foxy, My Life in Three Acts. Television producer Eunetta Boone will adapt the project, with Michael Becker producing alongside Bennie Richburg and Alfred Sapse.",
    link:
      "https://thefilmstage.com/news/pam-griers-autobiography-foxy-my-life-in-three-acts-coming-to-the-big-screen/",
    website: "thefilmstage.com",
  },
  {
    title: "Pam Grier's Formative Years Subject Of Feature",
    content:
      "Imprint Entertainment (TWILIGHT, Percy Jackson and the Olympians: The Lightning Thief) has optioned the film rights to Grier’s autobiography Foxy, My Life in Three Acts.",
    link:
      "https://deadline.com/2012/04/pam-griers-formative-years-subject-of-movie-253296/",
    website: "deadline.com",
  },
  {
    title:
      "Well Go USA Acquires ‘Kid Cannabis'; Drug Drama Expected to Hit Theaters in Time for 4/20",
    content:
      "John Stockwell’s indie movie stars Jonathan Daniel Brown (“Project X”), Kenny Wormald, Ron Perlman and John C. McGinley.",
    link:
      "https://www.thewrap.com/well-go-usa-acquires-kid-cannabis-drug-drama-will-hit-theaters-time-420-exclusive/",
    website: "thewrap.com",
  },
  {
    title: "Don’t Just Pass the Joint, Sell It",
    content:
      "Selling or using marijuana remains illegal in plenty of states, but on screen, the underground marijuana business is already being treated with a “Happy Days” glow of nostalgia. That’s the tone of “Kid Cannabis,” a rollicking tale of border-running teenage pot entrepreneurs based on the real-life story of Nate Norman.",
    link:
      "https://www.nytimes.com/2014/04/18/movies/kid-cannabis-about-a-teenage-drug-kingpin.html?_r=0",
    website: "nytimes.com",
  },
  {
    title: "Jamie Foxx To Star In, Produce Animated ‘Groove Tails’ (Yahoo)",
    content:
      "Jamie Foxx has signed on as the voice of the lead character in Groove Tails, a new CGI animated family movie on which he will also serve as co-producer. The story, set in the world of competitive street dancing competitions, but for mice, follows “Biggz”, a mouse deep in debt to a local club hoping to clean up the streets from a group of menacing alley cats, and get the girl.",
    link:
      "https://www.yahoo.com/entertainment/jamie-foxx-star-produce-animated-190004009.html",
    website: "yahoo.com",
  },
  {
    title: "Jamie Foxx To Star In, Produce Animated ‘Groove Tails’ (Deadline)",
    content:
      "Jamie Foxx has signed on as the voice of the lead character in Groove Tails, a new CGI animated family movie on which he will also serve as co-producer. The story, set in the world of competitive street dancing competitions, but for mice, follows “Biggz”, a mouse deep in debt to a local club hoping to clean up the streets from a group of menacing alley cats, and get the girl.",
    link:
      "https://deadline.com/2015/10/animated-film-groove-tails-starring-jamie-foxx-announced-1201567461/",
    website: "deadline.com",
  },
  {
    title: "Uma Thurman’s ‘Girl Soldier’ Gets Financing Partners (Yahoo)",
    content:
      "Imprint Entertainment has just come aboard to help finance Girl Soldier, based on Kathy Cook’s book Stolen Angels: The Kidnapped Girls of Uganda, which awhile back drew the interest of Uma Thurman to star and produce.",
    link:
      "https://www.yahoo.com/entertainment/uma-thurman-girl-soldier-gets-211845018.html",
    website: "yahoo.com",
  },
  {
    title: "Uma Thurman’s ‘Girl Soldier’ Gets Financing Partners (Deadline)",
    content:
      "Imprint Entertainment has just come aboard to help finance Girl Soldier, based on Kathy Cook’s book Stolen Angels: The Kidnapped Girls of Uganda, which awhile back drew the interest of Uma Thurman to star and produce.",
    link:
      "https://deadline.com/2016/02/uma-thurman-girl-soldier-movie-stolen-angels-book-financing-partner-imprint-1201707881/",
    website: "deadline.com",
  },
  {
    title: "Uma Thurman is a ‘Girl Soldier’",
    content:
      "Uma Thurman will topline indie drama “Girl Soldier” for Caspian Pictures, portraying a cleric who helped rescue 140 schoolgirls abducted in Uganda.",
    link:
      "https://variety.com/2009/film/markets-festivals/uma-thurman-is-a-girl-soldier-1118006252/",
    website: "variety.com",
  },
  {
    title: "First Look at Staecy Dash, LisaRaye in indie film Monica",
    content:
      "Here’s the first look at LisaRaye McCoy and Stacey Dash in Gregory Carter‘s independent film, Monica, where a young woman has to choose between the love of her life and the lure of money that takes place against the backdrop of a gentleman’s club.",
    link:
      "https://www.blackfilm.com/read/2013/02/first-look-at-staecy-dash-lisaraye-in-indie-film-monica/",
    website: "blackfilm.com",
  },
  {
    title: "Imprint Nabs ‘Godsent’ Rights",
    content:
      "Imprint Entertainment (“Twilight,” “Kid Cannabis”) has acquired the movie rights to “Godsent” from Richard Burton and Arcade Publishing, an imprint of Skyhorse Publishing, the company announced on Monday.",
    link: "https://www.thewrap.com/twilight-producer-nabsgodsent-rights-62586/",
    website: "thewrap.com",
  },
  {
    title: "",
    content: "",
    link: "",
    website: ""
  }
]

export default pressData